import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';
import { COUNTRY_BASE_FRAGMENT } from '@/graphql/_Fragments/Country/Base';
import {
  COMMUNITY_USER_CONNECTION_FULL_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserConnection/Full';
import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';

export const COMMUNITY_CRM_USER_CARD_FRAGMENT = `
  fragment communityCrmUserCardFragment on CommunityUser {
    id
    uid
    schemaCode
    firstName
    lastName
    name
    prefix
    suffix
    jobTitle
    employerName
    profilePrivacyMode
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    _ourConnection(myUid: "%authUser%") {
      ...communityUserConnectionFullFragment
    }
    _ourSharedInterestsCompanyCount(companyUid: "%companyUid%")
    country {
      ...countryBaseFragment
    }
    _connectionCount
    _connections(offset: 0, first: 3) {
      ...communityUserBaseFragment
    }
    _isRecommendedForMe
    _actions (actions: ["CAN_MEET", "CAN_CONNECT"]) {
      value
      key
    }
  }
  ${COMMUNITY_USER_CONNECTION_FULL_FRAGMENT}
  ${FILE_RESOURCE_BASE_FRAGMENT}
  ${COUNTRY_BASE_FRAGMENT}
  ${COMMUNITY_USER_BASE_FRAGMENT}
`;
